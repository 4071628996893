.popup__submit-button {
  width: calc(100% - 72px);
  box-sizing: border-box;
  max-width: 100%;
  min-height: 50px;
  background-color: #000;
  text-align: center;
  font-size: 18px;
  line-height: 1.22;
  color: #fff;
  margin-bottom: 34px;
}

/* styles for 320-527 */
@media screen and (max-width: 527px) {
  .popup__submit-button {
    width: calc(100% - 44px);
    font-size: 14px;
    min-height: 46px;
  }
}
