.auth__submit-button {
  width: 100%;
  margin-top: 168px;
  min-height: 50px;
  border-radius: 2px;
  font-size: 18px;
  color: #000;
  border: none;
}

.auth__submit-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 567px) {
  .auth__submit-button {
    width: calc(100% - 60px);
  }
}
