.gallery__image {
  aspect-ratio: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  object-position: center;
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}
