.popup__error-message {
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
  color: #000000;
  margin: auto auto;
  padding: 0;
}

/* styles for 320-527 */
@media screen and (max-width: 527px) {
  .popup__title {
    font-size: 18px;
    margin: 25px 0 57px 0;
  }
}
