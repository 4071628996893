.gallery__bin-button {
  display: none;
  position: absolute;
  background-image: url(../../../images/Trash.svg);
  height: 19px;
  width: 18px;
  right: 20px;
  top: 20px;
  border: none;
}
