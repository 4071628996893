.profile {
  display: grid;
  grid-template-columns: 120px 1fr 200px;
}
/* styles for 320-918 */
@media screen and (max-width: 918px) {
  .profile {
    max-width: calc(100% - 38px);
  }
}
@media screen and (max-width: 740px) {
  .profile {
    grid-template-columns: 120px 1fr;
  }
}

@media screen and (max-width: 527px) {
  .profile {
    grid-template-columns: 1fr;
  }
}
