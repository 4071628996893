.popup__form-item {
  width: calc(100% - 72px);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.214;
  color: #000000;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 0 9px 0;
  box-sizing: border-box;
}
@media screen and (max-width: 567px) {
  .popup__form-item {
    width: calc(100% - 44px);
  }
}
.popup__form-item:focus {
  outline: 2px solid rgba(68, 143, 214, 0.5);
  border-radius: 2px;
}
.popup__form-item:invalid {
  border-bottom: 1px solid #ff0000;
}
