.gallery__info-about-liked-users {
  display: none;
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 70%;
  background-color: white;
  z-index: 5;
  border-radius: 5px;
  border: 1px solid gray;
}
