.profile__edit-profile-button {
  flex-shrink: 0;
  margin: 36px 0 0 19px;
  width: 24px;
  height: 24px;
  background-image: url(../../../images/edit_button.svg);
  background-size: 10px;
}

/* styles for 320-527*/
@media screen and (max-width: 527px) {
  .profile__edit-profile-button {
    margin-top: 34px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
    background-size: 7.5px;
  }
}
