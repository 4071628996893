.footer__copyright {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  color: #545454;
}

/* styles for 320-934 */
@media screen and (max-width: 934px) {
  .footer__copyright {
    font-size: 14px;
    margin-left: 19px;
  }
}
