.gallery__city {
  grid-row-start: 2;
  grid-row-end: 4;
  display: block;
  margin: 25px 0 25px 21px;
  padding: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.208;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
