.profile__avatar-edit-button {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none !important;
}

@media screen and (max-width: 527px) {
  .profile__avatar-edit-button {
    justify-self: center;
  }
}
.profile__avatar-edit-button:hover::after {
  content: url(../../../images/editavatarbutton.svg);
}
