.gallery__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(282px, 1fr)); */
  row-gap: 20px;
  column-gap: 17px;
}

@media screen and (max-width: 760px) {
  .gallery__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 440px) {
  .gallery__list {
    grid-template-columns: repeat(1, 1fr);
  }
}
