.gallery__item {
  display: grid;
  grid-template-columns: 1fr 62px;
  grid-template-rows: 1fr, min-content;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}
