.popup {
  display: flex;
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: visibility 0.7s, opacity 0.7s;
}
