.button {
  background-color: transparent;
  padding: 0;
  border: 1px solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  box-sizing: border-box;
}

.button:hover {
  opacity: 0.6;
}
