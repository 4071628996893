.profile__user-name {
  display: block;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
  color: #fff;
  margin: 22px 0 0 0;
  padding-left: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 526px;
}

/* styles for 320-527 */
@media screen and (max-width: 527px) {
  .profile__user-name {
    font-size: 27px;
    line-height: 1.22;
    margin-top: 26px;
    text-align: center;
  }
}
