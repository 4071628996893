.page__gallery {
  margin: 50px auto 0 auto;
}

/* styles for 320-934 */
@media screen and (max-width: 933px) {
  .page__gallery {
    margin-top: 36px;
  }
}
