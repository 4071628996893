.popup__place-name-of-big-photo {
  margin: 10px 0 0 0;
  padding: 0;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  align-self: flex-start;
  color: #fff;
}
