.gallery__author {
  display: none;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
  height: 10%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 5;
}
