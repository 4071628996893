.auth__input {
  background: transparent;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 14px;
  width: 100%;
  padding: 0 0 13px 0;
  color: white;
}
@media screen and (max-width: 567px) {
  .auth__input {
    width: calc(100% - 60px);
  }
}
