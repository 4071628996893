.popup__title {
  align-self: self-start;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
  color: #000000;
  margin: 34px 0 36px 36px;
  padding: 0;
}

/* styles for 320-527 */
@media screen and (max-width: 527px) {
  .popup__title {
    align-self: center;
    font-size: 18px;
    margin: 25px 0 57px 0;
    text-align: center;
  }
}
