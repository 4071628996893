.profile__user-profession {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  color: #fff;
  margin: 7px 0 0 0;
  word-wrap: break-word;
  padding-left: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 526px;
}

/* styles for 320-527 */
@media screen and (max-width: 527px) {
  .profile__user-profession {
    padding: 0;
    font-size: 14px;
    margin-top: 7px;
    text-align: center;
  }
}
