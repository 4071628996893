@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter_400.woff2") format("woff2"),
    url("./Inter_400.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Inter_500.woff2") format("woff2"),
    url("./Inter_500.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Inter_900.woff2") format("woff2"),
    url("./Inter_900.woff") format("woff");
}
