.gallery {
  display: block;
}

/* styles for 320-918 */
@media screen and (max-width: 918px) {
  .gallery {
    max-width: calc(100% - 38px);
  }
}
