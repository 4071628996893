.popup__close-button {
  position: absolute;
  top: -40px;
  right: -40px;
  height: 32px;
  width: 32px;
  background-image: url(../../../images/close_popup_icon.svg);
  background-size: cover;
  border: none;
}

/* styles for 320-527 */
@media screen and (max-width: 527px) {
  .popup__close-button {
    height: 20px;
    width: 20px;
    top: -36px;
    right: 0px;
  }
}
