.page__footer {
  margin: 67px auto 60px auto;
}

/* styles for 320-934 */
@media screen and (max-width: 934px) {
  .page__footer {
    margin-top: 48px;
    margin-bottom: 36px;
  }
}
